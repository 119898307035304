import { amgApi } from "@/service/axios";

class ClientDashboard {
  // changeStatus
  async changeStatus(params) {
    const data = await amgApi.post("/administration/change-status", params);
    return data;
  }

  async requestChangeOfStatus(params) {
    const data = await amgApi.post(
      "/administration/request-change-of-status",
      params
    );
    return data;
  }

  async getDataClientAccount(params) {
    const data = await amgApi.post(
      "/administration/get-data-client-account",
      params
    );
    return data;
  }

  async insertMotiveForLoyal(params) {
    const data = await amgApi.post(
      "/administration/insert-motive-for-loyal",
      params
    );
    return data;
  }

  async getMotivesForLoyal(params) {
    const data = await amgApi.post(
      "/administration/get-motives-for-loyal",
      params
    );
    return data;
  }
}

export default new ClientDashboard();
