<template>
  <b-modal
    v-model="ownModal"
    title="CHANGE STATUS"
    size="lg"
    no-close-on-backdrop
    @hidden="close"
  >
    <div>
      <b-container fluid>
        <validation-observer ref="form">
          <b-row>

            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="Status"
                rules="required"
              >
                <b-form-group
                  label="Status"
                  label-class="font-weight-bolder"
                >
                  <b-select
                    v-model="statusData"
                    :options="statusOpts"
                    value-field="id"
                    text-field="value"
                  />
                  <span
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    Programs {{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="Messages"
                rules="required"
              >
                <b-form-group
                  label="Messages"
                  label-class="font-weight-bolder"
                >
                  <b-form-input

                    v-model="subject"
                    :disabled="true"
                    placeholder="Write new message"
                    :class="{ 'border-danger': errors[0] }"
                  />
                  <span
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    Messages {{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>

            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="Programs"
                rules="required"
              >
                <b-form-group
                  label=""
                  label-class="font-weight-bolder"
                >
                  <b-form-textarea
                    v-model="content"
                    placeholder="Enter description"
                    class="input-form"
                  />
                  <span
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    Message {{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </b-container>
    </div>

    <template #modal-footer>
      <b-button
        :disabled="spinner"
        variant="info"
        class="rounded"
        @click="saveStatus()"
      >Save<b-spinner
        v-if="spinner"
        class="ml-1"
        small
      /> </b-button>
    </template>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex'
import ClientDashboard from '@/views/administration/views/clients/service/client.service'

export default {

  props: {
    idaccount: {
      type: String,
      required: false,
    },
    statuschange: {
      type: Number,
      required: false,
    },
    advisorid: {
      type: Number,
      required: false,
    },
    clientname: {
      type: String,
      required: false,
    },
    accountName: {
      type: String,
      required: false,
    },
    idprogram: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      spinner: false,
      subject: `${this.clientname} | ${this.accountName}`,
      ownModal: true,
      statusData: this.statuschange,
      statusOpts: [

        // { id: 1, value: 'Active' },
        // { id: 2, value: 'Hold' },
        // { id: 5, value: 'Loyal' },
        // { id: 3, value: 'Transition' },
        // { id: 6, value: 'Closed' },
        // { id: 4, value: 'Cancelled' },
        { id: 12, value: 'Potential' },
        { id: 13, value: 'Stand By' },
          { id: 4, value: 'Canceled' }, 
          { id: 6, value: 'Closed' }, 

      ],
      content: '',

    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

  },

  methods: {
    async saveStatus() {
      try {
        const validate = await this.$refs.form.validate()
        if (validate) {
          this.spinner = true
          const params = {
            status: this.statusData,
            id: this.idaccount,
            session_id: this.currentUser.user_id,
            subject: this.subject,
            content: this.content.replace(/\r?\n/g, '<br />'),
            advisorid: this.advisorid,
            idprogram: this.idprogram,
          }

          const data = await ClientDashboard.changeStatus(params)
          if (data.status == 200) {
            this.spinner = false
            this.close()
            this.showSuccessSwal('Success', 'Status has been changed')
            this.$emit('refresh')
          }
        }
      } catch (e) {
        this.spinner = false
        console.log(e)
      }
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";

</style>
