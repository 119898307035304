<template>
  <b-modal
    v-model="ownModal"
    title="REQUEST CHANGE OF STATUS"
    size="sm"
    no-close-on-backdrop
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    header-class="p-0"
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Request Change of Status
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="close"
          />
        </div>
      </div>
    </template>
    <div>
      <b-container fluid>
        <validation-observer ref="form">
          <b-row>
            <b-col>
              <validation-provider
                v-slot="{ errors }"
                name="Status"
                rules="required"
              >
                <b-form-group label="Status" label-class="font-weight-bolder">
                  <b-select
                    v-model="statusData"
                    :options="getOptions"
                    value-field="id"
                    text-field="value"
                  />
                  <span v-if="errors[0]" class="text-danger">
                    Programs {{ errors[0] }}</span
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="statusData == 12">
              <legend
                tabindex="-1"
                class="bv-no-focus-ring col-form-label pt-0 font-weight-bolder"
              >
                Motive
              </legend>

              <validation-provider
                v-slot="{ errors }"
                name="Programs"
                rules="required"
              >
                <b-input-group
                  label="Description"
                  label-class="font-weight-bolder"
                >
                  <v-select
                    v-model="content"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    label="description"
                    :options="motives"
                    class="form-control bg-transparent hardcode-select"
                    :class="errors[0] ? 'is-invalid' : ''"
                    :reduce="(option) => option.description"
                  />
                  <!-- append btn -->
                  <b-input-group-append>
                    <b-button variant="primary" @click="addMotiveModal = true">
                      +
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </validation-provider>
            </b-col>
            <b-col v-else>
              <validation-provider
                v-slot="{ errors }"
                name="Programs"
                rules="required"
              >
                <b-form-group
                  label="Description    "
                  label-class="font-weight-bolder"
                >
                  <b-form-textarea
                    v-model="content"
                    placeholder="Enter description"
                    class="input-form"
                    rows="3"
                    no-resize
                  />
                  <span v-if="errors[0]" class="text-danger">
                    Message {{ errors[0] }}</span
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </b-container>
    </div>

    <template #modal-footer>
      <b-button
        :disabled="spinner"
        variant="info"
        class="rounded"
        :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
        @click="saveStatus()"
      >
        Save
        <b-spinner v-if="spinner" class="ml-1" small />
      </b-button>
    </template>

    <b-modal
      v-model="addMotiveModal"
      modal-class="modal-primary"
      title-class="h3 text-white"
      title="NEW MOTIVE"
      size="sm"
      @hidden="addMotiveModal = false"
      centered
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <legend
            tabindex="-1"
            class="bv-no-focus-ring col-form-label pt-0 font-weight-bolder"
          >
            Motive
          </legend>

          <b-input-group label="Description" label-class="font-weight-bolder">
            <b-form-input
              id="boomark-search-input"
              v-model="motive"
              placeholder="Add new motive"
              autofocus
            />
          </b-input-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          @click="insertMotiveForLoyal()"
          :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import ClientDashboard from "@/views/administration/views/clients/service/client.service";

export default {
  props: {
    status: {
      type: Number,
      required: false,
    },
    subjectClient: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      spinner: false,
      subject: this.subjectClient,
      ownModal: true,
      statusData: null,
      statusOpts: [
        { id: 12, value: "Loyal [ Potential ]" },
        { id: 13, value: "Loyal [ Stand By ]" },
        { id: 4, value: "Outstanding [ Canceled ]" },
        { id: 6, value: "Outstanding [ Closed ]" },
      ],
      addMotiveModal: false,
      motives: [],
      motive: "",
      content: "",
    };
  },
  created() {
    this.getMotivesForLoyal();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    getOptions() {
      switch (this.status) {
        case 8:
          return this.statusOpts.filter((item) => [12, 4, 6].includes(item.id));
        case 9:
          return this.statusOpts.filter((item) => [12, 4, 6].includes(item.id));
        case 10:
          return this.statusOpts.filter((item) => [12, 4, 6].includes(item.id));
        case 2:
          return this.statusOpts.filter((item) => [4, 6].includes(item.id));
        case 11:
          return this.statusOpts.filter((item) => [12, 13].includes(item.id));
        case 12:
          return this.statusOpts.filter((item) => [13].includes(item.id));
        default:
          break;
      }
    },
  },

  methods: {
    async getMotivesForLoyal() {
      try {
        const { data } = await ClientDashboard.getMotivesForLoyal({
          module_id: this.moduleId,
        });
        this.motives = data;
      } catch (e) {
        console.log(e);
      }
    },

    async insertMotiveForLoyal() {
      try {
        await ClientDashboard.insertMotiveForLoyal({
          module_id: this.moduleId,
          description: this.motive,
        });
        this.addMotiveModal = false;
        this.getMotivesForLoyal();
        this.showSuccessSwal("Success", "Motives has been added");
      } catch (e) {
        console.log(e);
      }
    },
    async saveStatus() {
      try {
        const validate = await this.$refs.form.validate();
        if (validate) {
          const response = await this.showConfirmSwal(
            "Are you sure?",
            "You won't be able to revert this!"
          );
          if (response.isConfirmed) {
            if ([6, 4].includes(this.statusData)) {
              await this.RequestChangeStatus();
            } else {
              await this.changeStatus();
            }
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.removePreloader();
      }
    },
    async changeStatus() {
      try {
        this.addPreloader();
        const params = {
          status: this.statusData,
          id: this.$route.params.idClient,
          session_id: this.currentUser.user_id,
          subject: this.subjectClient,
          content: this.content,
          advisorid: this.currentUser.user_id,
          idprogram: this.currentUser.program_id,
        };
        const data = await ClientDashboard.changeStatus(params);
        if (data.status == 200) {
          this.close();
          this.showSuccessSwal("Success", "Status has been changed");
          this.$emit("refresh");
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.removePreloader();
      }
    },
    async RequestChangeStatus() {
      try {
        this.addPreloader();
        const params = {
          content: this.content,
          new_status: this.statusData,
          old_status: this.status,
          user_id: this.currentUser.user_id,
          client_account_id: this.$route.params.idClient,
          module_id: this.moduleId,
        };

        const data = await ClientDashboard.requestChangeOfStatus(params);
        if (data.status == 200) {
          this.close();
          this.showSuccessSwal("Success", "Status change has been requested");
          this.$emit("refresh");
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.removePreloader();
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: none !important;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
  border: none !important;
}
.w-20 {
  width: 20%;
}
.w-70 {
  width: 70%;
}
.hardcode-kendo .k-picker-wrap {
  border: none !important;
  margin-top: -3px;
}
.hardcode-select .vs__dropdown-toggle {
  border: none;
  background: transparent !important;
  margin-top: -5px;
}
</style>