<template>
  <div>
    <b-skeleton-wrapper :loading="skeleton">
      <template #loading>
        <b-card no-body>
          <b-skeleton width="100%" style="min-width: 100px" />
        </b-card>
      </template>
    </b-skeleton-wrapper>

    <div class="d-flex" v-if="status_id">
      <div>
        <span v-if="G_PROGRAMS_IN_NEW_PAYMENT.includes(parseInt(program_id))">
          <feather-icon
            :icon="parent_icon"
            size="12"
            :style="parentFillColors(parent_name)"
            :class="parentColor(parent_name)"
          />
          <span :class="parentColor(parent_name)">
            {{ parent_name }}
          </span>
          <span :style="'color: ' + color_status">
            {{ "[ " + status + " ]" }}
          </span>
        </span>
        <span v-else>
          <IconStatusAccount :status="status_id" />
        </span>
      </div>
      <div>
        <feather-icon
          icon="ListIcon"
          class="cursor-pointer text-primary ml-1"
          size="15"
          @click="openModalHistory"
        />
        <span
          v-if="
            G_PROGRAMS_IN_NEW_PAYMENT.includes(parseInt(program_id)) &&
            loyal_return_to_active_count > 0
          "
          class="ml-1 text-info"
          v-b-tooltip
          :title="`This client has returned from Loyal to Active status ${loyal_return_to_active_count} times`"
          style="font-weight: 900;"
        >
          [ {{ loyal_return_to_active_count }} ]
        </span>
      </div>

      <div
        v-if="
          (isChief || isCeo || isSupervisor) &&
          ![4, 6, 13].includes(status_id) &&
          G_PROGRAMS_IN_NEW_PAYMENT.includes(parseInt(program_id)) &&
          moduleId != 4
        "
        class="text-center"
      >
        <feather-icon
          v-if="change_status != 1"
          icon="EditIcon"
          class="cursor-pointer text-warning ml-1"
          size="15"
          @click="openModalEdit"
        />
        <feather-icon
          v-else
          icon="RotateCcwIcon"
          class="cursor-pointer text-info rotate-infinite ml-1"
          size="15"
          v-b-tooltip.hover.top="'Change status pending'"
        />
      </div>
      <feather-icon
        class="cursor-pointer"
        icon="Edit2Icon"
        v-if="moduleId == 4"
        @click="openChanStatusAd(client.status)"
      />
    </div>
    <modal-history
      v-if="modalHistory"
      :modal-history="modalHistory"
      :name-program="name_module"
      :name-client="name_client"
      @closeModalHistory="closeModalHistory"
    />
    <send-change-status
      v-if="modalEdit"
      :status="status_id"
      :subject-client="name_client + '|' + name_account"
      @close="closeModalEdit"
      @refresh="getDataClientAccount()"
    />
    <change-status-ad
      v-if="openChanStatusAdOn"
      :account-name="client.account"
      :clientname="client.client_name"
      :statuschange="clientStatus"
      :idaccount="client.id"
      :advisorid="client.advisor_id"
      :idprogram="client.program_id"
      @refresh="$emit('refresh')"
      @close="closeChangeStatusAd"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ClientDashboard from "@/views/administration/views/clients/service/client.service";
import ModalHistory from "@/views/commons/components/clients/dashboard/information-client/modals/ModalHistory.vue";
import sendChangeStatus from "@/views/administration/views/clients/modals/sendChangeStatus.vue";
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";
import ChangeStatusAd from "@/views/administration/views/clients/modals/ChangeStatusAd.vue";

export default {
  components: {
    ModalHistory,
    sendChangeStatus,
    IconStatusAccount,
    ChangeStatusAd,
  },
  props: {
    client: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      status: "",
      status_id: null,
      color_status: "",
      name_module: "",
      name_client: "",
      name_account: "",
      change_status: null,
      modalHistory: false,
      modalEdit: false,
      parent_id: null,
      parent_color: "",
      parent_name: "",
      parent_icon: "",
      skeleton: true,
      program_id: null,
      openChanStatusAdOn: false,
      clientStatus: "",
      loyal_return_to_active_count: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",
    }),
    styleIcon() {
      return (
        "color: " +
        this.color_status +
        "; background-color: " +
        this.color_status +
        "; border-radius: 50%" +
        "; margin-right: 5px;"
      );
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },
  async created() {
    await this.destroySocket();
    await this.activeSocketChangeStatus();
  },
  async mounted() {
    await this.getDataClientAccount();
  },
  methods: {
    openChanStatusAd(clienStatus) {
      this.openChanStatusAdOn = true;
      this.clientStatus = clienStatus;
    },

    closeChangeStatusAd() {
      this.openChanStatusAdOn = false;
    },

    parentColor(name) {
      const colors = {
        Loyal: "text-primary",
        Active: "text-success",
        Outstanding: "text-danger",
      };
      return colors[name];
    },

    parentFillColors(name) {
      const colors = {
        Loyal: "fill: #007bff",
        Active: "fill: #28a745",
        Outstanding: "fill: #dc3545",
      };
      return colors[name];
    },
    async getDataClientAccount() {
      try {
        const params = {
          account_id: this.$route.params.idClient,
        };
        const { data } = await ClientDashboard.getDataClientAccount(params);
        this.status = data[0].status;
        this.color_status = data[0].color;
        this.name_client = data[0].client_name;
        this.name_module = data[0].name_program;
        this.name_account = data[0].name_account;
        this.status_id = data[0].id_status;
        this.program_id = data[0].program_id;

        this.parent_id = data[0].parent_id;
        this.parent_color = data[0].parent_color;
        this.parent_name = data[0].parent_name;
        this.parent_icon = data[0].parent_icon;
        this.change_status = data[0].change_status;
        this.loyal_return_to_active_count =
          data[0].loyal_return_to_active_count;
        this.skeleton = false;
      } catch (e) {
        console.log(e);
      }
    },

    close() {
      this.$emit("close");
    },

    openModalHistory() {
      this.modalHistory = true;
    },
    closeModalHistory() {
      this.modalHistory = false;
    },
    openModalEdit() {
      this.modalEdit = true;
    },
    closeModalEdit() {
      this.modalEdit = false;
    },
    async activeSocketChangeStatus() {
      try {
        window.socket.subscribe("channel-chat");
        window.socket.bind("change-status-client-refresh", async (response) => {
          this.closeModalEdit();
          await this.getDataClientAccount();
        });
      } catch (error) {
        console.log("Socket-binnacle: ", error);
      }
    },
    async destroySocket() {
      return new Promise((resolve, reject) => {
        try {
          window.socket.unbind("change-status-client-refresh");
          resolve(true);
        } catch (error) {
          reject(false);
          console.log(error);
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";

.option-status {
  opacity: 0;
  display: none;
}

.status:hover {
  .option-status {
    transition: 0.5s;
    opacity: 1;
    display: block;
  }
}

.rotate-infinite {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
