var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-skeleton-wrapper',{attrs:{"loading":_vm.skeleton},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-card',{attrs:{"no-body":""}},[_c('b-skeleton',{staticStyle:{"min-width":"100px"},attrs:{"width":"100%"}})],1)]},proxy:true}])}),(_vm.status_id)?_c('div',{staticClass:"d-flex"},[_c('div',[(_vm.G_PROGRAMS_IN_NEW_PAYMENT.includes(parseInt(_vm.program_id)))?_c('span',[_c('feather-icon',{class:_vm.parentColor(_vm.parent_name),style:(_vm.parentFillColors(_vm.parent_name)),attrs:{"icon":_vm.parent_icon,"size":"12"}}),_c('span',{class:_vm.parentColor(_vm.parent_name)},[_vm._v(" "+_vm._s(_vm.parent_name)+" ")]),_c('span',{style:('color: ' + _vm.color_status)},[_vm._v(" "+_vm._s("[ " + _vm.status + " ]")+" ")])],1):_c('span',[_c('IconStatusAccount',{attrs:{"status":_vm.status_id}})],1)]),_c('div',[_c('feather-icon',{staticClass:"cursor-pointer text-primary ml-1",attrs:{"icon":"ListIcon","size":"15"},on:{"click":_vm.openModalHistory}}),(
          _vm.G_PROGRAMS_IN_NEW_PAYMENT.includes(parseInt(_vm.program_id)) &&
          _vm.loyal_return_to_active_count > 0
        )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"ml-1 text-info",staticStyle:{"font-weight":"900"},attrs:{"title":("This client has returned from Loyal to Active status " + _vm.loyal_return_to_active_count + " times")}},[_vm._v(" [ "+_vm._s(_vm.loyal_return_to_active_count)+" ] ")]):_vm._e()],1),(
        (_vm.isChief || _vm.isCeo || _vm.isSupervisor) &&
        ![4, 6, 13].includes(_vm.status_id) &&
        _vm.G_PROGRAMS_IN_NEW_PAYMENT.includes(parseInt(_vm.program_id)) &&
        _vm.moduleId != 4
      )?_c('div',{staticClass:"text-center"},[(_vm.change_status != 1)?_c('feather-icon',{staticClass:"cursor-pointer text-warning ml-1",attrs:{"icon":"EditIcon","size":"15"},on:{"click":_vm.openModalEdit}}):_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Change status pending'),expression:"'Change status pending'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer text-info rotate-infinite ml-1",attrs:{"icon":"RotateCcwIcon","size":"15"}})],1):_vm._e(),(_vm.moduleId == 4)?_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"Edit2Icon"},on:{"click":function($event){return _vm.openChanStatusAd(_vm.client.status)}}}):_vm._e()],1):_vm._e(),(_vm.modalHistory)?_c('modal-history',{attrs:{"modal-history":_vm.modalHistory,"name-program":_vm.name_module,"name-client":_vm.name_client},on:{"closeModalHistory":_vm.closeModalHistory}}):_vm._e(),(_vm.modalEdit)?_c('send-change-status',{attrs:{"status":_vm.status_id,"subject-client":_vm.name_client + '|' + _vm.name_account},on:{"close":_vm.closeModalEdit,"refresh":function($event){return _vm.getDataClientAccount()}}}):_vm._e(),(_vm.openChanStatusAdOn)?_c('change-status-ad',{attrs:{"account-name":_vm.client.account,"clientname":_vm.client.client_name,"statuschange":_vm.clientStatus,"idaccount":_vm.client.id,"advisorid":_vm.client.advisor_id,"idprogram":_vm.client.program_id},on:{"refresh":function($event){return _vm.$emit('refresh')},"close":_vm.closeChangeStatusAd}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }